<template>
	<b-row>
		<b-col cols="12">
			<b-button-toolbar style="margin-bottom: 2rem">
				<b-button-group class="border" style="display: flex; width: 30%">
					<b-button
						v-for="(v, i) in status"
						:key="i"
						:variant="index == i ? 'primary' : 'white'"
						style="flex: 1"
						@click="
							index = i;
							key = v.key;
						"
						>{{ v.text }}
					</b-button>
				</b-button-group>
			</b-button-toolbar>
			<component :is="key"></component>
		</b-col>
	</b-row>
</template>

<script>
	import { inject, reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "index",
		components: {
			staffingIndex: () => import("../staffingIndex"),
			staffingTrack: () => import("../staffingTrack"),
			faceSearch: () => import("../faceSearch"),
			blackList: () => import("../blackList"),
		},
		setup() {
			const data = reactive({
				index: 0,
				status: [
					{
						text: "轨迹还原",
						active: false,
						key: "staffingTrack",
					},
					{
						text: "人脸检索",
						active: false,
						key: "faceSearch",
					},
					{
						text: "黑名单管理",
						active: false,
						key: "blackList",
					},
				],
				key: "staffingIndex",
			});
			const key = inject("key");
			data.key = key;
			return {
				...toRefs(data),
			};
		},
	};
</script>

<style lang="scss" scoped>
	.top {
		height: 7%;
	}

	.bottom {
		height: 93%;
	}
</style>
